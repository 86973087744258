export const MenuItems = [
  {
    title: "Ladder",
    tlink: "/ladder",
    tTarget: "_self",
  },
  {
    title: "Who Is",
    tlink: "/whois",
    tTarget: "_self",
  },
];
