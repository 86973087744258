import "./Footer.css";

export default function Footer({}) {
  return (
    <>
      <div class="main"></div>
      <div class="footer">
        <div class="bubbles">
          <div
            class="bubble"
            style={{
              "--size": "3.439882509995882rem",
              "--distance": "7.298946466468858rem",
              "--position": "11.48057911060939%",
              "--time": "2.7565880023648583s",
              "--delay": "-3.533354642604083s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.64540496070611rem",
              "--distance": "7.624650617952048rem",
              "--position": "82.68756731029012%",
              "--time": "2.0697275726285875s",
              "--delay": "-2.3515698704800685s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.683329370508131rem",
              "--distance": "7.710280857623059rem",
              "--position": "70.3959340367866%",
              "--time": "3.923484135616949s",
              "--delay": "-3.234424299802607s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.358519432636303rem",
              "--distance": "9.838300580791547rem",
              "--position": "2.4342598353325196%",
              "--time": "3.0936375698935263s",
              "--delay": "-2.7139393704253827s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.992659179682032rem",
              "--distance": "7.2556638507630415rem",
              "--position": "49.68118067170302%",
              "--time": "3.6053757391714796s",
              "--delay": "-3.845554677789635s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.1620927659713427rem",
              "--distance": "9.322560404894823rem",
              "--position": "23.6472400625191%",
              "--time": "2.237327941169004s",
              "--delay": "-3.1116556697592146s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.4342073423339645rem",
              "--distance": "8.138003999991792rem",
              "--position": "77.73249488064272%",
              "--time": "2.9472239477365303s",
              "--delay": "-3.621450057485481s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.805924186723783rem",
              "--distance": "9.43886572129809rem",
              "--position": "62.956615547140004%",
              "--time": "3.907597711416101s",
              "--delay": "-2.087161154614768s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.823084376120398rem",
              "--distance": "8.916342387211206rem",
              "--position": "-1.6088507456691747%",
              "--time": "3.1258972113573815s",
              "--delay": "-3.263330748587087s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.730093417808957rem",
              "--distance": "6.369942830177275rem",
              "--position": "76.732891865453%",
              "--time": "2.112093128040578s",
              "--delay": "-3.262582557232233s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.700824385736443rem",
              "--distance": "9.987280224288025rem",
              "--position": "85.96763880361605%",
              "--time": "2.808946917825779s",
              "--delay": "-3.7068596913212244s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.2111941286537125rem",
              "--distance": "6.783648680613273rem",
              "--position": "67.02086096039862%",
              "--time": "3.1967857000853437s",
              "--delay": "-2.8864317684000547s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.50134803413484rem",
              "--distance": "8.660629382640947rem",
              "--position": "-3.4765253384475647%",
              "--time": "3.021273176612003s",
              "--delay": "-3.827598806966678s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.806432431035494rem",
              "--distance": "7.085463224054379rem",
              "--position": "83.33676970507149%",
              "--time": "3.181313067530172s",
              "--delay": "-3.4637551028146514s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.097805578577768rem",
              "--distance": "9.287479131427567rem",
              "--position": "85.91791788441233%",
              "--time": "3.7562572669566214s",
              "--delay": "-2.4553766335153324s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.1931260057645456rem",
              "--distance": "8.120664093040766rem",
              "--position": "54.32214716974553%",
              "--time": "2.458294204547655s",
              "--delay": "-3.8868132244285247s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.506614408035647rem",
              "--distance": "7.0723672310033585rem",
              "--position": "93.7946868522212%",
              "--time": "2.536921476902442s",
              "--delay": "-3.686519494783363s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.394174475895147rem",
              "--distance": "6.178670655084064rem",
              "--position": "3.201936250145895%",
              "--time": "3.8707835590907727s",
              "--delay": "-2.995965841317064s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.4440911047108758rem",
              "--distance": "8.03329557402686rem",
              "--position": "46.18921045039715%",
              "--time": "3.31407460309845s",
              "--delay": "-2.730224492397863s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.050264817572181rem",
              "--distance": "8.646812422470422rem",
              "--position": "50.9088524963538%",
              "--time": "2.858037060752745s",
              "--delay": "-2.602410468108877s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.102009606144831rem",
              "--distance": "8.41970125074014rem",
              "--position": "74.76318971139713%",
              "--time": "3.1056863507043193s",
              "--delay": "-2.4944245921760766s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.817058575372532rem",
              "--distance": "8.943925483930737rem",
              "--position": "7.118046119338771%",
              "--time": "3.4360413770576237s",
              "--delay": "-2.957961039143363s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.923283320569449rem",
              "--distance": "7.689337100070502rem",
              "--position": "77.58435725951969%",
              "--time": "2.3873347320773406s",
              "--delay": "-3.2277797279019618s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.6884664310804878rem",
              "--distance": "6.719694553228419rem",
              "--position": "94.53323378261553%",
              "--time": "3.184261648213246s",
              "--delay": "-3.1666471710395285s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.9432593558380598rem",
              "--distance": "7.511676687208554rem",
              "--position": "81.97362001921357%",
              "--time": "2.8762056482440572s",
              "--delay": "-2.956319749494442s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.390614902025807rem",
              "--distance": "6.021852321814893rem",
              "--position": "9.20890293028352%",
              "--time": "2.7822396487673164s",
              "--delay": "-3.8694317913632132s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.68926353651679rem",
              "--distance": "6.56306512534631rem",
              "--position": "2.033416383201474%",
              "--time": "3.7491397668671596s",
              "--delay": "-3.3993004529095208s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.547794729359762rem",
              "--distance": "8.397921818110783rem",
              "--position": "62.7272453479919%",
              "--time": "2.1228420839776745s",
              "--delay": "-2.728930189660784s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.8403889671615206rem",
              "--distance": "6.4171721678557665rem",
              "--position": "4.555645538755373%",
              "--time": "3.486025499856477s",
              "--delay": "-3.8947204002666855s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.8767835882988875rem",
              "--distance": "7.489277164999242rem",
              "--position": "56.204949643674944%",
              "--time": "3.5224101729641633s",
              "--delay": "-2.597537995150952s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.1319016345306325rem",
              "--distance": "7.460177588267389rem",
              "--position": "78.75435027780298%",
              "--time": "2.690062708961412s",
              "--delay": "-2.5573787371423977s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.60782383741242rem",
              "--distance": "6.785008705246569rem",
              "--position": "55.854536110084894%",
              "--time": "2.2514039573281543s",
              "--delay": "-3.823543612756819s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.85081827492318rem",
              "--distance": "9.797802888010285rem",
              "--position": "85.4224267488206%",
              "--time": "2.817571708791013s",
              "--delay": "-2.887111376369743s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.478270187399943rem",
              "--distance": "6.321475139849921rem",
              "--position": "69.68240852252919%",
              "--time": "2.6588208498761627s",
              "--delay": "-3.2031136456845677s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.6795027979461175rem",
              "--distance": "8.450485911859744rem",
              "--position": "104.47736788151167%",
              "--time": "2.7448952768642343s",
              "--delay": "-2.6278450356820047s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.1053757848857826rem",
              "--distance": "6.207018546502757rem",
              "--position": "97.19813096204882%",
              "--time": "2.27662963001391s",
              "--delay": "-2.574171669552954s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.315268513248225rem",
              "--distance": "8.151547590050223rem",
              "--position": "87.67050944393331%",
              "--time": "3.5911220514112636s",
              "--delay": "-2.983689876196966s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.4222511896377066rem",
              "--distance": "6.724759594637374rem",
              "--position": "33.1753265442858%",
              "--time": "3.745214143627128s",
              "--delay": "-3.5627017763830575s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.7131292722648004rem",
              "--distance": "7.887052948356515rem",
              "--position": "34.804139388193114%",
              "--time": "2.0627356023294134s",
              "--delay": "-2.7601814772084405s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.1866903951782017rem",
              "--distance": "9.513302226368777rem",
              "--position": "1.3875188007640604%",
              "--time": "2.297085233043451s",
              "--delay": "-2.076558143918404s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.0945889535933837rem",
              "--distance": "6.130032195849819rem",
              "--position": "44.72222168458652%",
              "--time": "2.731546676042929s",
              "--delay": "-3.9606489733645063s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.64891543764949rem",
              "--distance": "9.549608678306848rem",
              "--position": "27.162390672301214%",
              "--time": "3.609649755047389s",
              "--delay": "-3.8928803117937743s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.346340854496103rem",
              "--distance": "6.99503450803886rem",
              "--position": "60.03192061307992%",
              "--time": "3.7282961512061443s",
              "--delay": "-3.6638411568437896s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.614687291144842rem",
              "--distance": "7.275693003171791rem",
              "--position": "88.22206136920938%",
              "--time": "3.698064401784423s",
              "--delay": "-2.820753910230803s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.657492209694068rem",
              "--distance": "9.278508698170167rem",
              "--position": "67.19779145580931%",
              "--time": "2.1500879329510036s",
              "--delay": "-3.356212398649273s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.672429601945727rem",
              "--distance": "9.734742599709042rem",
              "--position": "98.31265658218138%",
              "--time": "2.4948730768584175s",
              "--delay": "-3.863357336921879s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.7046050965011874rem",
              "--distance": "7.9463666659677585rem",
              "--position": "76.1561896777896%",
              "--time": "2.7214685940100702s",
              "--delay": "-2.4027683316232507s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.439320779937577rem",
              "--distance": "8.805557541770545rem",
              "--position": "91.26668384124378%",
              "--time": "2.7915697608466443s",
              "--delay": "-3.329499765730675s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.015590100550475rem",
              "--distance": "6.728152044643128rem",
              "--position": "31.522670111895422%",
              "--time": "3.104667655192361s",
              "--delay": "-2.516881372501114s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.03727629255671rem",
              "--distance": "6.235607891109911rem",
              "--position": "-4.363155641684147%",
              "--time": "2.5362082224352083s",
              "--delay": "-3.3983964837665055s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.2247390602623094rem",
              "--distance": "9.4192268369405rem",
              "--position": "8.075568337527148%",
              "--time": "3.8412142238456872s",
              "--delay": "-2.3870882432043827s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.858028614496506rem",
              "--distance": "6.808327133152605rem",
              "--position": "14.27045945696597%",
              "--time": "2.5914927319003582s",
              "--delay": "-3.1165275364350236s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.3862156233223084rem",
              "--distance": "9.076191102602191rem",
              "--position": "0.8008182248116835%",
              "--time": "2.7293514201713065s",
              "--delay": "-2.272261487328138s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.709041392217987rem",
              "--distance": "7.883840425239478rem",
              "--position": "15.878758558265574%",
              "--time": "3.159119572817354s",
              "--delay": "-3.546976181482626s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.482146524867956rem",
              "--distance": "7.68296978516376rem",
              "--position": "18.251230297193747%",
              "--time": "3.819945027450392s",
              "--delay": "-2.2943489507380663s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.1988670861216866rem",
              "--distance": "9.124843923216792rem",
              "--position": "98.39189567735015%",
              "--time": "3.7555708511095465s",
              "--delay": "-3.685185141503623s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.386875956786036rem",
              "--distance": "7.941074943723014rem",
              "--position": "88.70053017525012%",
              "--time": "2.244800263543969s",
              "--delay": "-2.2501024951041924s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.8891631509092566rem",
              "--distance": "6.808354100848475rem",
              "--position": "27.553404599740766%",
              "--time": "3.7596155640403848s",
              "--delay": "-2.4666448992320413s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.7893192321374025rem",
              "--distance": "6.930638646131232rem",
              "--position": "33.86732231118703%",
              "--time": "2.350735259355825s",
              "--delay": "-3.75113787381962s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.732608501121068rem",
              "--distance": "9.907687047161168rem",
              "--position": "23.146276381123645%",
              "--time": "2.6886088584525587s",
              "--delay": "-3.45999899810858s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.297800633664839rem",
              "--distance": "6.089225970876249rem",
              "--position": "51.466276273482244%",
              "--time": "3.640022120613275s",
              "--delay": "-2.013676244087603s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.538902138019167rem",
              "--distance": "7.037912908594997rem",
              "--position": "43.25887495703139%",
              "--time": "3.6079623698724967s",
              "--delay": "-3.1528497171193233s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.1879301411537rem",
              "--distance": "7.978470148582087rem",
              "--position": "70.01071537119937%",
              "--time": "3.4473989990417877s",
              "--delay": "-3.6775192631708316s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.9354675191146296rem",
              "--distance": "9.680710686302223rem",
              "--position": "45.7747929093002%",
              "--time": "3.3172634142908053s",
              "--delay": "-2.786807948726457s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.3124716618976136rem",
              "--distance": "8.737909271801604rem",
              "--position": "85.04289441860377%",
              "--time": "3.7749625719363102s",
              "--delay": "-2.6627965927275423s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.3921336811004545rem",
              "--distance": "8.210267306736139rem",
              "--position": "29.874626071850315%",
              "--time": "3.7488014730787738s",
              "--delay": "-3.3670225441885293s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.5688635507419892rem",
              "--distance": "6.085261136107553rem",
              "--position": "19.012606129780757%",
              "--time": "2.3215382357543355s",
              "--delay": "-2.1409638046806294s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.718736452726562rem",
              "--distance": "9.53201692918135rem",
              "--position": "93.81647623354105%",
              "--time": "3.5060610242181784s",
              "--delay": "-2.560491653631156s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.732232648210319rem",
              "--distance": "6.456269719297094rem",
              "--position": "-1.8462565355779947%",
              "--time": "3.762516971675243s",
              "--delay": "-3.7285314160840484s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.7916803628930253rem",
              "--distance": "6.326910009651339rem",
              "--position": "47.692584981927894%",
              "--time": "2.353750768157126s",
              "--delay": "-2.5173993314272693s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.451022244292613rem",
              "--distance": "6.175889055523925rem",
              "--position": "102.86910345055227%",
              "--time": "3.410473887581814s",
              "--delay": "-3.9311305812423893s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.9327236266453376rem",
              "--distance": "7.221594219446411rem",
              "--position": "27.62091688375093%",
              "--time": "2.5475066339744648s",
              "--delay": "-3.494701551899765s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.630646737482814rem",
              "--distance": "7.84121233755021rem",
              "--position": "-0.03962463660860838%",
              "--time": "3.278089491437441s",
              "--delay": "-3.786790442329137s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.0251678353058935rem",
              "--distance": "8.704751864448559rem",
              "--position": "30.083266946052575%",
              "--time": "3.054955359286052s",
              "--delay": "-3.751606515237442s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.2501746253005113rem",
              "--distance": "9.399216848854078rem",
              "--position": "69.21004128418573%",
              "--time": "2.3725760826221545s",
              "--delay": "-3.1114370946588594s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.840748466998856rem",
              "--distance": "6.161928388875528rem",
              "--position": "5.97226725494388%",
              "--time": "3.516754584332622s",
              "--delay": "-3.2451353713873488s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.456144572154149rem",
              "--distance": "8.328942223194737rem",
              "--position": "30.319296264227155%",
              "--time": "3.4737180430577563s",
              "--delay": "-2.669288861545165s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.42394351693328rem",
              "--distance": "9.922453145522852rem",
              "--position": "14.280929037570075%",
              "--time": "3.9130218355414756s",
              "--delay": "-3.5592549141405576s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.940784125571424rem",
              "--distance": "8.670858892258458rem",
              "--position": "92.19509753422622%",
              "--time": "3.3853845536074787s",
              "--delay": "-3.418798900195949s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.370508468659844rem",
              "--distance": "6.393699756118953rem",
              "--position": "40.155712306989834%",
              "--time": "3.123208999524256s",
              "--delay": "-2.382610520503232s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.922021635328485rem",
              "--distance": "6.787361729185282rem",
              "--position": "66.12295440293768%",
              "--time": "2.8654725487809714s",
              "--delay": "-2.898741178489564s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.097469143473952rem",
              "--distance": "7.594437396605993rem",
              "--position": "97.86364514656174%",
              "--time": "3.108973745829981s",
              "--delay": "-2.654133365649686s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.3311008428632016rem",
              "--distance": "7.829125977311401rem",
              "--position": "42.3547155918762%",
              "--time": "2.9982760892125557s",
              "--delay": "-2.2754779334073065s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.1083177820245655rem",
              "--distance": "8.693016070801695rem",
              "--position": "75.52443893640368%",
              "--time": "3.734605738176854s",
              "--delay": "-2.3343203809718127s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.20612071825518rem",
              "--distance": "9.816736377304013rem",
              "--position": "70.45688895645996%",
              "--time": "3.392177799645468s",
              "--delay": "-3.3801156702051265s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.6546413515526757rem",
              "--distance": "8.172251854588625rem",
              "--position": "80.8306307742978%",
              "--time": "3.3158058865594375s",
              "--delay": "-3.012444241190531s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.679836397015216rem",
              "--distance": "6.516104599592718rem",
              "--position": "7.253297204505763%",
              "--time": "2.7846802118725873s",
              "--delay": "-2.8596170162345276s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.75705169516747rem",
              "--distance": "6.430060397735074rem",
              "--position": "1.0958950239541991%",
              "--time": "3.590491978673436s",
              "--delay": "-3.8597419669042066s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.947440313611584rem",
              "--distance": "6.265387777839767rem",
              "--position": "24.13383250750238%",
              "--time": "2.886515172460471s",
              "--delay": "-3.6056308738822427s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.3205842533513454rem",
              "--distance": "9.41556084826869rem",
              "--position": "61.81635489344919%",
              "--time": "3.2801411900775252s",
              "--delay": "-2.625738868464721s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.0051525600105755rem",
              "--distance": "6.962238397165637rem",
              "--position": "37.4447552361233%",
              "--time": "2.560384773562417s",
              "--delay": "-2.774140185132469s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.803654476554352rem",
              "--distance": "8.838710974589432rem",
              "--position": "-3.9104781335037675%",
              "--time": "2.27784552118198s",
              "--delay": "-2.4525568717658297s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.337318807693rem",
              "--distance": "9.612680933071706rem",
              "--position": "102.16391295914063%",
              "--time": "2.253449021028912s",
              "--delay": "-3.8505838418233367s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.373232057882114rem",
              "--distance": "7.868388676429887rem",
              "--position": "86.5065506180812%",
              "--time": "2.7286720258528026s",
              "--delay": "-3.80433379045558s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.6897746763747366rem",
              "--distance": "7.541604236645467rem",
              "--position": "74.09811776411232%",
              "--time": "2.642058261270092s",
              "--delay": "-3.5154991557368778s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.6422942780047807rem",
              "--distance": "6.77608816566956rem",
              "--position": "38.07170671446773%",
              "--time": "3.0616554789676544s",
              "--delay": "-3.358824564335038s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.833727656532436rem",
              "--distance": "7.059556177773327rem",
              "--position": "64.86737801037373%",
              "--time": "3.756931783359144s",
              "--delay": "-2.789507902649971s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.6356984027858905rem",
              "--distance": "7.513029421598267rem",
              "--position": "80.69125133563541%",
              "--time": "2.38124694624576s",
              "--delay": "-2.8408702872698344s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.245168009524848rem",
              "--distance": "6.388106697759932rem",
              "--position": "71.64275926268763%",
              "--time": "2.655427926619199s",
              "--delay": "-3.7291825385144866s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.1335339662273585rem",
              "--distance": "8.163516938054642rem",
              "--position": "51.65986603304923%",
              "--time": "3.8285815451711973s",
              "--delay": "-3.719316890233038s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.2087036283272674rem",
              "--distance": "7.854720428543287rem",
              "--position": "33.22434247600414%",
              "--time": "2.910660828312708s",
              "--delay": "-2.599224419182549s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.999319329560861rem",
              "--distance": "8.797867894809507rem",
              "--position": "22.868099542068457%",
              "--time": "3.0210174186932055s",
              "--delay": "-3.045174188576679s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.363851935032821rem",
              "--distance": "7.751479213308133rem",
              "--position": "45.97888762394036%",
              "--time": "2.0460213896832595s",
              "--delay": "-3.635006578666674s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.8339836038391155rem",
              "--distance": "8.060049488790877rem",
              "--position": "101.90507514834674%",
              "--time": "3.557680543955692s",
              "--delay": "-2.8306005801970806s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.7761864003137733rem",
              "--distance": "7.195502921000421rem",
              "--position": "19.62620280777639%",
              "--time": "3.898201114689943s",
              "--delay": "-3.9637794950207947s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.3339082542490024rem",
              "--distance": "6.173318216723217rem",
              "--position": "-0.8676364731974822%",
              "--time": "3.484898981272463s",
              "--delay": "-2.2890590610456782s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.701152306407743rem",
              "--distance": "6.701166341077681rem",
              "--position": "95.5732671110031%",
              "--time": "3.766270671756878s",
              "--delay": "-2.8583599084674045s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.439615405932154rem",
              "--distance": "9.437416750959029rem",
              "--position": "29.21241534355171%",
              "--time": "3.7181075360102933s",
              "--delay": "-2.3221082066276817s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.927311438203519rem",
              "--distance": "9.616902450281259rem",
              "--position": "-2.6434313177540414%",
              "--time": "2.4060444403381633s",
              "--delay": "-3.5943424651275273s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.48152429094713rem",
              "--distance": "6.018439568437056rem",
              "--position": "2.6684205034290898%",
              "--time": "3.729224873363312s",
              "--delay": "-3.935669477373878s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.878066336045371rem",
              "--distance": "7.420548001510629rem",
              "--position": "81.98752730752925%",
              "--time": "2.410733614151344s",
              "--delay": "-2.8771182435099756s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.217001336080832rem",
              "--distance": "9.25458725900825rem",
              "--position": "73.7229240908592%",
              "--time": "2.928400056128854s",
              "--delay": "-2.664496322097537s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.6332398864685267rem",
              "--distance": "8.333209829131368rem",
              "--position": "70.07243464353111%",
              "--time": "3.0928372592008953s",
              "--delay": "-2.6135915330338206s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.18832125029563rem",
              "--distance": "8.65201350160106rem",
              "--position": "56.03921100959103%",
              "--time": "3.7472157404419524s",
              "--delay": "-3.5019027158441443s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.563759199446973rem",
              "--distance": "9.281772475938993rem",
              "--position": "51.97889855036921%",
              "--time": "3.378160930201519s",
              "--delay": "-2.074469862001223s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.909829742896015rem",
              "--distance": "7.399536722842979rem",
              "--position": "104.07424081179745%",
              "--time": "3.057154926569541s",
              "--delay": "-2.0763085962278014s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.766084074183782rem",
              "--distance": "6.827934764784058rem",
              "--position": "67.53032260285%",
              "--time": "2.593935445149339s",
              "--delay": "-2.1536452689942305s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.365360634041509rem",
              "--distance": "9.67166348957468rem",
              "--position": "33.226965197739936%",
              "--time": "3.367430237172069s",
              "--delay": "-2.40957516768532s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "4.998431273830655rem",
              "--distance": "9.962793570161601rem",
              "--position": "60.03338713407291%",
              "--time": "2.7113717066015735s",
              "--delay": "-2.461317292747823s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.375526294191472rem",
              "--distance": "8.637983940193571rem",
              "--position": "90.5767470676975%",
              "--time": "3.4951798411509456s",
              "--delay": "-3.010218590738214s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.4064713198414767rem",
              "--distance": "9.34479727849301rem",
              "--position": "22.331785517249283%",
              "--time": "2.890043877437826s",
              "--delay": "-2.311529257884631s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.794134208699422rem",
              "--distance": "9.650311821472979rem",
              "--position": "100.31432955378654%",
              "--time": "3.6172048631359313s",
              "--delay": "-3.0436126585620005s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.30889062828966rem",
              "--distance": "9.114294322218212rem",
              "--position": "63.8095948281606%",
              "--time": "2.955652740907679s",
              "--delay": "-2.9266680897425084s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "2.6537595486220003rem",
              "--distance": "8.133316918758767rem",
              "--position": "1.5100210869131603%",
              "--time": "3.3551683824940346s",
              "--delay": "-3.608039244561252s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.769269022345463rem",
              "--distance": "7.153433779705991rem",
              "--position": "26.83799021132601%",
              "--time": "3.4062294216963607s",
              "--delay": "-2.6288098484347704s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "3.909158516066407rem",
              "--distance": "7.845203571521492rem",
              "--position": "2.743280290773993%",
              "--time": "3.5870339117873558s",
              "--delay": "-3.3383785359663296s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.178727468682295rem",
              "--distance": "9.741970329108668rem",
              "--position": "61.01311224720841%",
              "--time": "2.162130685630727s",
              "--delay": "-2.031863102185669s",
            }}
          ></div>
          <div
            class="bubble"
            style={{
              "--size": "5.874243689986271rem",
              "--distance": "8.62382475294941rem",
              "--position": "102.68785515596836%",
              "--time": "2.2071388437812223s",
              "--delay": "-3.5129049138463353s",
            }}
          ></div>
        </div>
        <div class="content flex text-center justify-center items-end">
          {/* <div class="flex flex-col justify-center text-center">
            <div>
              <span class="font-bold">
                ©{new Date().getFullYear()} (pls, bro) labs
              </span>
            </div>
            
          </div> */}
          {/* <div>
            <a href="https://www.twitter.com/0x_minifridge" target="_blank">
              <div class="flex flex-row justify-start items-center">
                <img src="./images/logo.png" class="w-[150px] md:w-[250px]" />
                <span>Minifridge</span>
              </div>
            </a>
          </div> */}
          {/* <div>
            <a
              class="image"
              target="_blank"
              style={{
                "background-image":
                  "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/199011/happy.svg')",
              }}
            ></a>
            
          </div> */}
          <p class="font-bold text-gray-400">
            created and curated by{" "}
            <a
              href="https://www.twitter.com/0x_minifridge"
              target="_blank"
              class="hover:text-black"
            >
              minifridge
            </a>
          </p>
        </div>
      </div>
      <svg style={{ position: "fixed", top: "100vh" }}>
        <defs>
          <filter id="blob">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            ></feGaussianBlur>
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="blob"
            ></feColorMatrix>
          </filter>
        </defs>
      </svg>
    </>
  );
}
