export const MintingCollectionData = [
  {
    name: "Monkeez",
    address: "0xeea030a1bc8fd534864a7a77d94dde0858b3c1fb",
    twitter: "https://twitter.com/MonkeezNFT",
    discord: "https://discord.com/invite/monkeez",
    website: "https://www.monkeez.io",
    previewImageUrl: "../images/monkee.png",
    price: "71",
    maxSupply: "1500",
  },
  {
    name: "The Ladder",
    address: "test",
    twitter: "https://twitter.com/TheLadder_",
    discord: "https://discord.com/invite/sD2A5mjuuH",
    website: "",
    previewImageUrl: "./images/ladder.png",
    price: "71",
    maxSupply: "1500",
  },
  {
    name: "The Ladder",
    address: "test",
    twitter: "https://twitter.com/TheLadder_",
    discord: "https://discord.com/invite/sD2A5mjuuH",
    website: "",
    previewImageUrl: "./images/ladder.png",
    price: "71",
    maxSupply: "1500",
  },
  {
    name: "The Ladder",
    address: "test",
    twitter: "https://twitter.com/TheLadder_",
    discord: "https://discord.com/invite/sD2A5mjuuH",
    website: "",
    previewImageUrl: "./images/ladder.png",
    price: "71",
    maxSupply: "1500",
  },
  {
    name: "The Ladder",
    address: "test",
    twitter: "https://twitter.com/TheLadder_",
    discord: "https://discord.com/invite/sD2A5mjuuH",
    website: "",
    previewImageUrl: "./images/ladder.png",
    price: "71",
    maxSupply: "1500",
  },
  {
    name: "The Ladder",
    address: "test",
    twitter: "https://twitter.com/TheLadder_",
    discord: "https://discord.com/invite/sD2A5mjuuH",
    website: "",
    previewImageUrl: "./images/ladder.png",
    price: "71",
    maxSupply: "1500",
  },
  {
    name: "The Ladder",
    address: "test",
    twitter: "https://twitter.com/TheLadder_",
    discord: "https://discord.com/invite/sD2A5mjuuH",
    website: "",
    previewImageUrl: "./images/ladder.png",
    price: "71",
    maxSupply: "1500",
  },
  {
    name: "The Ladder",
    address: "test",
    twitter: "https://twitter.com/TheLadder_",
    discord: "https://discord.com/invite/sD2A5mjuuH",
    website: "",
    previewImageUrl: "./images/ladder.png",
    price: "71",
    maxSupply: "1500",
  },
];
